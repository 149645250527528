import React, { useEffect, useRef, useState } from "react";

//dayjs
import dayjs from "dayjs";

//jquery
import $ from "jquery";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import {
  getUser,
  handleBlockUnblockSwitch,
  editName,
  isRoomAdmin,
} from "../../store/user/action";

//routing
import { Link, useHistory } from "react-router-dom";

//MUI
import { Tooltip } from "@material-ui/core";

// import arraySort from "array-sort";

//image
import Male from "../../assets/images/male.png";
import BlueTick from "../../assets/images/verified.png";

//pagination
import Pagination from "../../pages/Pagination";

//Date Range Picker
import { DateRangePicker } from "react-date-range";
//Calendar Css
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

//MUI icon
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { io } from "socket.io-client";
import { baseURL } from "../../util/Config";
import { UNSET_ADMIN } from "../../store/admin/types";
import { OPEN_USER_DIALOG } from "../../store/user/types";
import TagAssignToUser from "../dialog/TagAssignToUser";
import assignTag from "../../assets/images/assigntag.svg";
import UserInfoDialog from "../dialog/UserInfoDialog";
import { OPEN_REASON_DIALOGUE } from "../../store/hostRequest/types";

const UserTable = (props) => {
  const history = useHistory();
  const maxDate = new Date();
  const dispatch = useDispatch();

  // const [coinSort, setCoinSort] = useState(true);
  // const [followerSort, setFollowerSort] = useState(true);
  // const [followingSort, setFollowingSort] = useState(true);
  const [data, setData] = useState([]);

  const [activePage, setActivePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("ALL");

  const [date, setDate] = useState([]);
  const [sDate, setsDate] = useState("ALL");
  const [eDate, seteDate] = useState("ALL");

  const socketRef = useRef(null);
  const { admin } = useSelector((state) => state.admin);

  const ipAddress = localStorage.getItem("ipAddress");

  // useEffect(() => {
  //   if (ipAddress.length > 0) {
  //     const socket = io.connect(baseURL, {
  //       transports: ["websocket", "polling", "flashsocket"],
  //       query: {
  //         adminRoom: admin && admin?._id,
  //       },
  //     });
  //     socketRef.current = socket ? socket : null;
  //     socketRef.current.on("connect", () => {
  //       console.log("Socket connected");
  //       // socketRef.current.emit("ticket", ipAddress && ipAddress);
  //     });
  //     socketRef.current.on("checkIpAdress", (data) => {
  //       console.log("data", data);
  //       if (data !== ipAddress) {
  //         dispatch({ type: UNSET_ADMIN });
  //       }
  //     });
  //     socketRef.current.on("disconnect", () => {
  //       console.log("Socket disconnected");
  //     });
  //     socketRef.current.on("connect_error", (error) => {
  //       console.error("Socket connection error:", error);
  //     });
  //     return () => {
  //       console.log("Cleaning up socket connection");
  //       socketRef.current.disconnect();
  //     };
  //   }
  // }, [ipAddress]);

  useEffect(() => {
    $("#card").click(() => {
      $("#datePicker");
    });
  }, []);

  useEffect(() => {
    dispatch(getUser(activePage, rowsPerPage, search, sDate, eDate));
  }, [dispatch, activePage, rowsPerPage, search, sDate, eDate]);

  const { user, activeUser, male, female, totalUser } = useSelector(
    (state) => state.user
  );
  useEffect(() => {
    setData(user);
  }, [user]);

  useEffect(() => {
    if (date.length === 0) {
      setDate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    }
    $("#datePicker");
    setData(user);
  }, [date, user]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setActivePage(1);
    setRowsPerPage(value);
  };

  const handleBlockUnblockSwitch_ = (userId) => {
    props.handleBlockUnblockSwitch(userId);
  };
  const handleAdminVipSwitch_ = (userId) => {
    props.editName("", "adminVIP", userId);
  };

  const handleUserInfo = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    history.push("/admin/user/detail");
  };
  const handleUserHistory = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    history.push("/admin/user/history");
  };

  const getAllUser = () => {
    setActivePage(1);
    setsDate("ALL");
    seteDate("ALL");
    $("#datePicker");
    dispatch(getUser(activePage, rowsPerPage, sDate, eDate));
  };

  const collapsedDatePicker = () => {
    $("#datePicker").toggleClass("collapse");
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 3) {
      setSearch(e.target.value);
      setActivePage(1);
    } else {
      setSearch("ALL");
    }
  };

  const handleIsRoomAdmin = (id) => {
    props.isRoomAdmin(id);
  };

  const handleOpen = (data) => {
    dispatch({ type: OPEN_USER_DIALOG, payload: data });
  }

  // set default image

  $(document).ready(function () {
    $("img").bind("error", function () {
      // Set the default image
      $(this).attr("src", Male);
    });
  });

  const handleDecline = (id, type) => {
    dispatch({ type: OPEN_REASON_DIALOGUE, payload: { id: id, type: type } });
};

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3 text-light" style={{ color: "#e4eeff" }}>
              User
            </h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active " aria-current="page">
                  User
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-2 col-sm-12">
          <div class="row">
            <div class="col-lg-4">
              <div class="card stats-card">
                <div class="card-body">
                  <div class="stats-info">
                    <h5 class="card-title">
                      {male ? male : 0}
                      {/* <span class="stats-change stats-change-danger">-8%</span> */}
                    </h5>
                    <p class="stats-text">Male</p>
                  </div>
                  <div class="stats-icon change-danger">
                    <i class="material-icons">male</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card stats-card">
                <div class="card-body">
                  <div class="stats-info">
                    <h5 class="card-title">
                      {female ? female : 0}
                      {/* <span class="stats-change stats-change-danger">-8%</span> */}
                    </h5>
                    <p class="stats-text">Female</p>
                  </div>
                  <div class="stats-icon change-success">
                    <i class="material-icons">female</i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card stats-card">
                <div class="card-body">
                  <div class="stats-info">
                    <h5 class="card-title">{activeUser}</h5>
                    <p class="stats-text">Total Active User</p>
                  </div>
                  <div class="stats-icon change-pink">
                    <i class="material-icons">people</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card" id="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                  <div className="text-left align-sm-left d-md-flex d-lg-flex justify-content-start">
                    <button
                      className="btn btn-info"
                      style={{ marginRight: 5 }}
                      onClick={getAllUser}
                    >
                      All
                    </button>
                    <button
                      className="collapsed btn btn-info ml-5"
                      value="check"
                      data-toggle="collapse"
                      data-target="#datePicker"
                      onClick={collapsedDatePicker}
                    >
                      Analytics
                      <ExpandMoreIcon />
                    </button>
                    <p style={{ paddingLeft: 10 }} className="my-2 ">
                      {sDate !== "ALL" && sDate + " to " + eDate}
                    </p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        onChange={(e) => {
                          if (e.target.value.length === 0) {
                            setSearch(e.target.value);
                            setActivePage(1);
                          }
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            setSearch(e.target.value);
                            setActivePage(1);
                          }
                        }}
                      />
                      <div
                        className="input-group-prepend border-0"
                        for="searchBar"
                        onClick={() => {
                          // Use setSearch with the value of the input field
                          setSearch(document.getElementById("searchBar").value);
                          setActivePage(1);
                        }}
                      >
                        <div id="button-addon4" className="btn text-danger">
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  id="datePicker"
                  className="collapse mt-5 pt-5"
                  aria-expanded="false"
                >
                  <div className="container table-responsive">
                    <div key={JSON.stringify(date)}>
                      <DateRangePicker
                        maxDate={maxDate}
                        onChange={(item) => {
                          setDate([item.selection]);
                          const dayStart = dayjs(
                            item.selection.startDate
                          ).format("M/DD/YYYY");
                          const dayEnd = dayjs(item.selection.endDate).format(
                            "M/DD/YYYY"
                          );
                          setActivePage(1);
                          setsDate(dayStart);
                          seteDate(dayEnd);
                          props.getUser(
                            null,
                            activePage,
                            rowsPerPage,
                            sDate,
                            eDate
                          );
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                        direction="horizontal"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body card-overflow pt-0">
              <table class="table table-striped mt-5 text-center">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Image</th>
                    <th>Username</th>
                    <th>UniqueId</th>
                    <th>Email</th>
                    <th>Gender</th>
                    <th>RCoin</th>
                    <th>Country</th>
                    <th>Level</th>
                    <th>Vip</th>
                    {/* <th>Follower</th>
                    <th>Following</th> */}
                    <th>isRoomAdmin</th>
                    <th>isBlock</th>
                    <th>Tag Assign</th>

                    <th>Admin Vip</th>
                    <th>Make Host</th>

                    <th>Info</th>
                    <th>History</th>

                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    data.map((data, index) => {
                      console.log('data: ', data);
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <span className="position-relative">
                              <img
                                height="50px"
                                width="50px"
                                alt="app"
                                src={data?.image ? data?.image : Male}
                                style={{
                                  boxShadow:
                                    "0 5px 15px 0 rgb(105 103 103 / 0%)",
                                  border: "2px solid #fff",
                                  borderRadius: 10,
                                  objectFit: "cover",
                                  display: "block",
                                }}
                                className="mx-auto"
                              />
                              {data?.profileIdVerify === 2 ? (
                                <img
                                  height="20px"
                                  width="20px"
                                  alt="image"
                                  src={BlueTick}
                                  style={{
                                    position: "absolute",
                                    right: "-7px",
                                    top: "-8px",
                                    margin: "0",
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </span>
                          </td>
                          <td>{data?.name ? data?.name : "-"}</td>
                          <td>{data?.uniqueId ? data?.uniqueId : "-"}</td>
                          <td>{data?.email ? data?.email : "-"}</td>
                          <td>{data?.gender ? data?.gender : "-"}</td>
                          <td className="text-danger">
                            {data?.rCoin ? data?.rCoin : "0"}
                          </td>
                          <td className="text-success">
                            {data?.country ? data?.country : "-"}
                          </td>
                          <td className="text-warning">
                            {data?.level?.name ? data?.level?.name : "-"}
                          </td>
                          <td>
                            {data?.isVIP === true ? (
                              <span className="text-success">True</span>
                            ) : (
                              <span className="text-danger">False</span>
                            )}
                          </td>
                          {/* <td>{data?.followers ? data?.followers : "0"}</td>
                          <td>{data?.following ? data?.following : "0"}</td> */}

                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data?.isRoomAdmin}
                                onChange={() => handleIsRoomAdmin(data?._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${data?.isRoomAdmin ? "-24px" : "35px"
                                      }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data?.isRoomAdmin ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>

                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data?.isBlock}
                                onChange={() =>
                                  handleBlockUnblockSwitch_(data?._id)
                                }
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${data?.isBlock ? "-24px" : "35px"
                                      }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data?.isBlock ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>
                          <td className="text-center">
                            <Tooltip title="History">
                              <button
                                type="button"
                                className="btn btn-sm btn-success d-flex align-items-center justify-content-center"
                                onClick={() => handleOpen(data)}
                                style={{
                                  padding: "7px 17px",
                                  borderRadius: "4px",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "3px",
                                }}
                              >
                                <img
                                  src={assignTag}
                                  style={{ marginRight: "0px" }}
                                />
                                {/* <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ fontWeight: 700 }}
                                >
                                  <path
                                    d="M14.6316 14.0789C14.6316 14.0789 14.8827 14.0292 10.655 14.0292C6.42726 14.0292 3 17.4565 3 21.6842M10.655 10.7485C8.23914 10.7485 6.28071 8.7901 6.28071 6.37426C6.28071 3.95842 8.23914 2 10.655 2C13.0708 2 15.0293 3.95842 15.0293 6.37426C15.0293 8.7901 13.0708 10.7485 10.655 10.7485Z"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M16.625 16L15.3125 23M19.6875 16L18.375 23M14.4375 17.75H21M14 21.25H20.5625"
                                    stroke="white"
                                    strokeWidth="1.8"
                                    strokeLinecap="round"
                                  />
                                </svg> */}
                              </button>
                            </Tooltip>
                          </td>


                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={data?.adminVIP}
                                onChange={() => handleAdminVipSwitch_(data._id)}
                              />
                              <span className="slider">
                                <p
                                  style={{
                                    fontSize: 12,
                                    marginLeft: `${data?.adminVIP ? "-24px" : "35px"
                                      }`,
                                    color: "#000",
                                    marginTop: "6px",
                                  }}
                                >
                                  {data?.adminVIP ? "Yes" : "No"}
                                </p>
                              </span>
                            </label>
                          </td>

                          <td>
                            <button
                              class="btn btn-sm text-white success"
                              onClick={() =>
                                handleDecline(data, "accept")
                              }
                            >
                              <i class="fa fa-check"></i>
                            </button>
                          </td>

                          <td>
                            <Tooltip title="Info">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleUserInfo(data)}
                              >
                                <i className="fas fa-info-circle fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="History">
                              <button
                                type="button"
                                className="btn btn-sm btn-success"
                                onClick={() => handleUserHistory(data)}
                              >
                                <i className="fas fa-history fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>


                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                activePage={activePage}
                rowsPerPage={rowsPerPage}
                userTotal={totalUser}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
      <TagAssignToUser />
      <UserInfoDialog />

    </>
  );
};

export default connect(null, {
  getUser,
  handleBlockUnblockSwitch,
  editName,
  isRoomAdmin,
})(UserTable);
