import { CLOSE_HASHTAG_DIALOG, OPEN_HASHTAG_DIALOG } from "../hashtag/types";
import {
  CLOSE_TAG_DIALOG,
  CREATE_TAG,
  DELETE_TAG,
  EDIT_TAG,
  GET_TAG,
  OPEN_TAG_DIALOG,
} from "./types";

const initialState = {
  tag: [],
  dialog: false,
  dialogData: null,
};

const tagReduer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAG:
      return {
        ...state,
        tag: action.payload,
      };
    case CREATE_TAG:
      const data = [...state.tag];
      data.unshift(action.payload);
      return {
        ...state,
        tag: data,
      };
    case EDIT_TAG:
      return {
        ...state,
        tag: state.tag.map((tag) => {
          if (tag._id === action.payload.id) return action.payload.data;
          else return tag;
        }),
      };
    case DELETE_TAG:
      return {
        ...state,
        tag: state.tag.filter((tag) => tag._id !== action.payload),
      };
    case OPEN_TAG_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_TAG_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};

export default tagReduer;
