export const GET_USER = "GET_USER";
export const BLOCK_UNBLOCK_SWITCH = "BLOCK_UNBLOCK_SWITCH";

export const GET_HISTORY = "GET_HISTORY";

export const EDIT_COIN = "EDIT_COIN";

export const GET_LIVE_USER = "GET_LIVE_USER";
export const MAKE_HOST = "MAKE_HOST";

export const LIVE_CUT = "LIVE_CUT";
export const EDIT_NAME = "EDIT_NAME";
export const HASHTAG_ASSIGN_TO_USER = "HASHTAG_ASSIGN_TO_USER";
export const IS_ROOM_ADMIN_SWITCH = "IS_ROOM_ADMIN_SWITCH";
export const OPEN_USER_DIALOG = "OPEN_USER_DIALOG";
export const CLOSE_USER_DIALOG = "CLOSE_USER_DIALOG";


