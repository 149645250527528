import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { Cancel, PaymentOutlined } from "@material-ui/icons";

//types
import { CLOSE_BANNER_DIALOG } from "../../store/banner/types";

//action
import { createNewBanner, editBanner } from "../../store/banner/action";
import { baseURL } from "../../util/Config";
import { CLOSE_USER_DIALOG } from "../../store/user/types";
import Multiselect from "multiselect-react-dropdown";
import { getTag } from "../../store/tag/action";
import { HashTagAssignToUser } from "../../store/user/action";


const TagAssignToUser = (props) => {
    const dispatch = useDispatch();

    const { dialog: open, dialogData } = useSelector((state) => state.user);
    console.log('dialogData: ', dialogData);
    const { tag } = useSelector((state) => state.tag)
    console.log('tag: ', tag);

    const [mongoId, setMongoId] = useState("");
    const [link, setLink] = useState("");
    const [imageData, setImageData] = useState(null);
    const [imagePath, setImagePath] = useState(null);
    const [paymentGateway, setPaymentGateway] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedHashTag, setSelectedHashtag] = useState([]);


    const [errors, setError] = useState({
        link: "",
        image: ""
    });

    useEffect(() => {
        dispatch(getTag())
    }, [])

    useEffect(() => {
        if (dialogData) {
            setMongoId(dialogData._id);
            setLink(dialogData.URL);
            setImagePath(baseURL + dialogData.image);
            const selectedTags = tag.filter((t) => dialogData.assignedTag?.some((dt) => dt._id === t._id || dt === t._id));
            console.log('selectedTags: ', selectedTags);
            setSelectedHashtag(selectedTags); // Store full objects
            setPaymentGateway(selectedTags.map((item) => item._id)); // Store only IDs
        }
    }, [dialogData, tag]);

    useEffect(
        () => () => {
            setError({
                link: "",
                image: ""
            });
            setMongoId("");
            setLink("");
            setImageData(null);
            setImagePath(null);
            setPaymentGateway([]);
            setSelectedValue([]);
        },
        [open]
    );

    useEffect(() => {
        window.onbeforeunload = closePopup();
    }, []);


    const closePopup = () => {
        dispatch({ type: CLOSE_USER_DIALOG });
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        if (paymentGateway?.length === 0) {
            const errors = {};
            if (!paymentGateway?.length) errors.link = "Tag is Required!";

            return setError({ ...errors });
        } else {
            const payload = {
                userId: dialogData?._id,
                tagId: paymentGateway?.join(",")
            }

            dispatch(HashTagAssignToUser(payload, dialogData?._id))
            closePopup()
        }
    };

    function onSelect(selectedList, selectedItem) {
        setSelectedHashtag(selectedList); // Store full objects
        setPaymentGateway(selectedList.map((data) => data._id)); // Store IDs
    }

    // Handle removing selected tags
    function onRemove(selectedList, removedItem) {
        setSelectedHashtag(selectedList); // Store full objects
        setPaymentGateway(selectedList.map((data) => data._id)); // Store IDs
    }

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="responsive-dialog-title"
                onClose={closePopup}
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle id="responsive-dialog-title">
                    <span className="text-danger font-weight-bold h4"> Tag Assign To User </span>
                </DialogTitle>

                <IconButton
                    style={{
                        position: "absolute",
                        right: 0,
                    }}
                >
                    <Tooltip title="Close">
                        <Cancel className="text-danger" onClick={closePopup} />
                    </Tooltip>
                </IconButton>
                <DialogContent>
                    <div className="modal-body pt-1 px-1 pb-3">
                        <div className="d-flex flex-column">
                            <form>
                                <label className="mb-3">Select Tag</label>
                                <Multiselect
                                    options={tag} 
                                    selectedValues={selectedHashTag} 
                                    onSelect={onSelect} 
                                    onRemove={onRemove}
                                    displayValue="name"
                                />
                                {errors.link && (
                                    <div className="ml-2 mt-1">
                                        {errors.link && (
                                            <div className="pl-1 text__left">
                                                <span className="text-red">{errors.link}</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                                    <button
                                        type="button"
                                        className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                                        onClick={closePopup}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-round float__right btn-danger"
                                        onClick={handleSubmit}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default connect(null, { createNewBanner, editBanner })(TagAssignToUser);
