import {
  BLOCK_UNBLOCK_SWITCH,
  GET_USER,
  GET_HISTORY,
  EDIT_COIN,
  GET_LIVE_USER,
  LIVE_CUT,
  EDIT_NAME,
  IS_ROOM_ADMIN_SWITCH,
  OPEN_USER_DIALOG,
  CLOSE_USER_DIALOG,
  HASHTAG_ASSIGN_TO_USER,
  GET_HOST,
  MAKE_HOST,
} from "./types";

const initialState = {
  host: [],
  liveUser: [],
  dialog: false,
  dialogData: null,
  male: 0,
  female: 0,
  totalUser: 0,
  activeUser: 0,
  history: [],
  totalHistoryUser: 0,
  income: 0,
  outgoing: 0,
  totalCallCharge: 0,
  liveStreamingIncome: 0,
};

const hostReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST:
      
      return {
        ...state,
        host: action.payload.host,
        male: action.payload.male,
        female: action.payload.female,
        totalUser: action.payload.total,
        activeUser: action.payload.activeUser,
      };

    case GET_LIVE_USER:
      return {
        ...state,
        liveUser: action.payload,
      };

    case LIVE_CUT:
      return {
        ...state,
        liveUser: state.liveUser.filter(
          (data) => data?.liveStreamingId !== action.payload && data
        ),
      };

    case BLOCK_UNBLOCK_SWITCH:
      return {
        ...state,
        host: state.host.map((host) => {
          if (host._id === action.payload._id)
            return {
              ...host,
              isBlock: action.payload.isBlock,
            };
          else return host;
        }),
      };

    case GET_HISTORY:
      return {
        ...state,
        history: action.payload.history,
        totalHistoryUser: action.payload.total,
        income: action.payload.incomeTotal,
        outgoing: action.payload.outgoingTotal,
        totalCallCharge: action.payload.totalCallCharge,
        liveStreamingIncome: action.payload.income,
      };
    case EDIT_COIN:
      return {
        ...state,
        host: state.host.map((host) => {
          if (host._id === action.payload.id) return action.payload.data;
          else return host;
        }),
      };
    case EDIT_NAME:
      return {
        ...state,
        host: state.host.map((host) => {
          if (host._id === action.payload.id) return action.payload.data;
          else return host;
        }),
      };

      

      case HASHTAG_ASSIGN_TO_USER:
        
        return {
          ...state,
          host: state.host.map((host) => {
            if (host._id === action.payload.id) return action.payload.data;
            else return host;
          }),
        };

    case IS_ROOM_ADMIN_SWITCH:
      return {
        ...state,
        host: state.host.map((host) => {
          if (host._id === action?.payload?._id)
            return {
              ...host,
              isRoomAdmin: action.payload.isRoomAdmin,
            };
          else return host;
        }),
      };

    case OPEN_USER_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_USER_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };


    default:
      return state;
  }
};

export default hostReducer;
