import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  CLOSE_TAG_DIALOG,
  CREATE_TAG,
  DELETE_TAG,
  EDIT_TAG,
  GET_TAG,
} from "./types";
import { apiInstanceFetch } from "../../util/api";

export const getTag = () => (dispatch) => {
  apiInstanceFetch
    .get(`tag`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_TAG, payload: res?.data });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const createTag = (data) => (dispatch) => {
  axios
    .post(`tag`, data)
    .then((res) => {
      
      if (res.data.status) {
        Toast("success", "Tag created successfully!");
        dispatch({ type: CLOSE_TAG_DIALOG });
        dispatch({ type: CREATE_TAG, payload: res.data?.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editTag = (tagId, data) => (dispatch) => {
  axios
    .patch(`tag`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "Tag updated successfully!");
        dispatch({ type: CLOSE_TAG_DIALOG });
        dispatch({
          type: EDIT_TAG,
          payload: { data: res.data?.data, id: tagId },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const deleteTag = (tagId) => (dispatch) => {
  axios
    .delete(`tag?tagId=${tagId}`)
    .then((res) => {
      
      if (res.data.status) {
        dispatch({ type: DELETE_TAG, payload: tagId });
        Toast("success", "Tag Deleted successfully!");
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
