import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { acceptAgencyRedeem } from "../../store/agencyRedeem/action";
import { CLOSE_REASON_DIALOGUE } from "../../store/agencyRedeem/type";
import { makeHost } from "../../store/user/action";

const UserInfoDialog = () => {
    const dispatch = useDispatch();
    const { dialog: open, dialogData } = useSelector(
        (state) => state.agencyRedeem
    );

    const closePopup = () => {
        dispatch({ type: CLOSE_REASON_DIALOGUE });
    };
    const handleSubmit = () => {
        dispatch(makeHost(dialogData?.id?._id));
        closePopup();
    };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="responsive-dialog-title"
                onClose={closePopup}
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                sx={{ maxWidth: "400px" }}
            >
                <DialogTitle id="responsive-dialog-title">
                    <span className="text-danger font-weight-bold h4">
                        {" "}
                        User Info{" "}
                    </span>
                </DialogTitle>

                <IconButton
                    style={{
                        position: "absolute",
                        right: 0,
                    }}
                >
                    <Tooltip title="Close">
                        <Cancel className="text-danger" onClick={closePopup} />
                    </Tooltip>
                </IconButton>
                <DialogContent>
                    <div class="form-group col-12 mY-3">
                        <label class="mb-2 text-gray">Name</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Enter Reason"
                            required
                            value={dialogData?.id?.name}

                        />
                        <div className="mt-4">
                            <label class="mb-2 text-gray">Image</label>
                            <br></br>
                            <img
                                src={dialogData?.id?.image}
                                height={100}
                                width={100}
                                style={{ objectFit: "contain" }}
l
                            />
                        </div>
                        <div className={"my-3 pt-3"}>
                            <button
                                type="button"
                                className="btn btn-outline-info  ml-4 btn-round float__right icon_margin"
                                onClick={closePopup}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-round ml-3 float__right btn-danger"
                                onClick={handleSubmit}
                            >
                                Make Host
                            </button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default UserInfoDialog;
